import React, { useState, useEffect } from "react";
import swappingDinamic from "../assets/images/sell-price.png";
import pool from "../assets/images/buy-price.png";
import amm from "../assets/images/24hr.png";
import liquid from "../assets/images/Liquidity Pool.png";

import "../assets/CSS/style.css";
import { SwapRate } from "../hooks/useContractData";
import { toLocale } from "../hooks/useContract";
import { useGraphData } from "../hooks/useGraph";
import { formatUnits } from "viem";
import { DWCAddress, USDTAddress, chainRPC, swapContract } from "../config/index.js";
import ERC20ABI from "../abis/erc20.json";
import { ethers } from "ethers";

function Analytics() {
  const { tradeVolume } = useGraphData();

  const calculateTotal = () => {
    const stakedAmounts = tradeVolume.map((item) =>
      Number(formatUnits(item.USDT, 6))
    );
    const totalStakedAmount = stakedAmounts.reduce(
      (acc, curr) => acc + curr,
      0
    );
    return totalStakedAmount;
  };

  const swapVolume = calculateTotal();
  const { sellPrice, buyPrice, tradeVol, zebBalance, usdtBalance } = SwapRate();

  // console.debug(sellPrice, buyPrice, zebBalance, usdtBalance)

  const [tokenBal, setTokenBal] = useState([0, 0]);

  const getLiquidity = async () => {
    const provider = new ethers.providers.JsonRpcProvider(
      chainRPC
    );

    const DWCContract = new ethers.Contract(DWCAddress, ERC20ABI, provider);
    const USDTContract = new ethers.Contract(USDTAddress, ERC20ABI, provider);
    const dwcBal = await DWCContract.balanceOf(swapContract);
    const usdtBal = await USDTContract.balanceOf(swapContract);

    const dwcFinalBal = ethers.utils.formatUnits(dwcBal, 18);
    const usdtFinalBal = ethers.utils.formatUnits(usdtBal, 6);
    return [
      parseFloat(dwcFinalBal).toFixed(2),
      parseFloat(usdtFinalBal).toFixed(2),
    ];
  };

  useEffect(() => {
    if (!window) return;
    getLiquidity().then((res) => {
      setTokenBal(res);
    });
  }, []);

  return (
    <section className=" adminReportTable ">
      <div className="container">
      <div className="   ctr mt-15">
        <div className="titlebox text-center mt-52 ">
          <h2 className=" text-4xl md:text-5xl  font-semibold anltycs  ">
            <span className="anlytcs text-gradient   bg-gradient-to-br from-yellow-500 via-blue-500 to-blue-400 inline-block text-transparent bg-clip-text  transition-opacity duration-500">
              Swap Statistics
            </span>
          </h2>
        </div>
      </div>
  {/* <div className="analytics-boxmain p-4 mx-auto     rounded-2xl  max-w-5xl relative">

  <div className="flex flex-wrap justify-center  mx-auto  bg-[#08121f] rounded-2xl">
          <div className="w-full sm:w-1/2 lg:w-1/3 px-2 sm:px-4 py-4  ">
            <div className="cardbox2 justify-center   mx-auto flex ">
             
              <div className="flex flex-col justify-center items-center">
              <h2>{sellPrice}</h2>
              <p className="">
                Sell Price </p>
              <p className="mt-1 text-blue-500 ">1 EXBIT ~ {toLocale(sellPrice)} USDT</p>  
              </div>           
            </div>
          </div>
          <div className="w-full sm:w-1/2 lg:w-1/3 px-2 sm:px-4 py-4  ">
            <div className="cardbox2 justify-center   mx-auto flex ">
             
              <div className="flex flex-col justify-center items-center">
              <h2>{toLocale(1 / buyPrice)}</h2>
              <p>
                Buy Price </p>
              <p className=" mt-1 text-blue-500">1 EXBIT ~ {toLocale(1 / buyPrice)} USDT</p>  
              </div>
             
             
             
            </div>
          </div>
          <div className="w-full sm:w-1/2 lg:w-1/3 px-2 sm:px-4 py-4  ">
            <div className="cardbox2 justify-center   mx-auto flex ">
             
              <div className="flex flex-col justify-center items-center">

              <h2>${tradeVol + swapVolume}</h2>
              <p className=" mt-1 text-blue-500">24h Trade Volume</p>
              </div>
             
              
            </div>
          </div>
          <div className="w-full sm:w-1/2 lg:w-1/3 px-2 sm:px-4 py-4   ">
            <div className="cardbox2 justify-center   mx-auto  flex">
            
              <div className="flex flex-col justify-center items-center">
                  {console.log("tokenBal: ", tokenBal[0])}
                {console.log("tokenLiquidity: ", tokenLiquidity[0])}
                
              <h2>{Number(zebBalance)} EXBIT</h2>
              <p className=" mt-1 text-blue-500">EXBIT Liquidity Pool</p>
              </div>
            
             
            </div>
          </div>

          <div className="w-full sm:w-1/2 lg:w-1/3 px-2 sm:px-4 py-4   ">
            <div className="cardbox2 justify-center   mx-auto flex ">
             
              <div className="flex flex-col justify-center items-center">
              <h2>{Number(usdtBalance)} USDT</h2>
              <p className=" mt-1 text-blue-500">USDT Liquidity Pool</p>
              </div>
            </div>
          </div>
       
      </div>
  </div> */}
  <div className="max-w-lg token-table rounded-xl px-5 py-5 mx-auto ">
                  <ul className="token-update__data list-unstyled">
                    <li>
                      <span className=""> <p> Sell Price</p> 1 EXBIT ~ {toLocale(sellPrice)} USDT </span>
                     
                      <strong className="text-blue-500 text-center">
                        {" "}
                     
                         
                        {sellPrice} 
                    
                      </strong>
                    </li>
                    <li>
                      <span> Buy Price    <p className=" ">1 EXBIT ~ {toLocale(1 / buyPrice)} USDT</p>  </span>
                      <strong className="text-blue-500 text-center">{toLocale(1 / buyPrice)}</strong>
                    </li>
                    <li>
                      <span>EXBIT Liquidity Pool</span>
                      <strong className="text-blue-500 text-center">
                        {/* {Number(zebBalance).toFixed(2)} EXBIT */}
                        4,000,000 EXBIT
                      </strong>
                    </li>
                    <li>
                      <span>USDT Liquidity Pool</span>
                      <strong className="text-blue-500 text-center">
                        {" "}
                        {/* {Number(usdtBalance).toFixed(2)} USDT */}
                        200,000 USDT
                      </strong>
                    </li>
                  
                  </ul>
                </div>

      </div>
    
    </section>
  );
}

export default Analytics;
