import { useAccount, useNetwork } from "wagmi";
import logo from "../../assets/images/exbitlogo.webp";

import {
  useAccountModal,
  useChainModal,
  useConnectModal,
} from "@rainbow-me/rainbowkit";
import { shortAddress } from "../../hooks/useContract";
import { defaultNetwork } from "../../config";

const Header = () => {
  const { openConnectModal } = useConnectModal();
  const { openAccountModal } = useAccountModal();
  const { openChainModal } = useChainModal();
  const { address } = useAccount();
  const { chain } = useNetwork();
  const isCorrectNetwork = chain?.id === defaultNetwork;

  return (
    <header className="py-2">
      <div className="container mx-auto">
        <div className="flex items-center justify-between my-2">
          <div className="w-1/2 md:w-1/4 text-left">
            <div className="logo">
              <img src={logo} alt="logo" className="block w-16 h-16" />
            </div>
          </div>

          <div className="w-1/2 md:w-1/4 text-right">
            <div className="rightboxmenu">
              <ul className="balancebox list-none m-0">
                <li>
                  {/* Connect Button */}
                  {openConnectModal && !address && (
                    <div className="bnrbtn transition-all duration-300 relative ease-in-out transform hover:scale-105">
                      <button
                        className="w-32 flex justify-center p-3 relative border-white rounded-lg bg-gradient-to-b from-blue-500 to-custom-dark-end hover:from-blue-600  hover:to-custom-dark-end"
                        onClick={openConnectModal}
                        type="button"
                      >
                        Connect
                      </button>
                    </div>
                  )}

                  {/* Account Button */}
                  {openAccountModal && address && (
                    <div className="bnrbtn transition-all duration-300 relative ease-in-out transform hover:scale-105">
                      <button
                        className="w-32 flex justify-center p-4 relative border-white rounded-lg bg-gradient-to-b from-blue-500 to-custom-dark-end hover:from-blue-600  hover:to-custom-dark-end"
                        onClick={openAccountModal}
                        type="button"
                      >
                        {shortAddress(address)}
                      </button>
                    </div>
                  )}

                  {/* Wrong Network Button */}
                  {!isCorrectNetwork && openChainModal && (
                    <div className="bnrbtn transition-all duration-300 relative ease-in-out transform hover:scale-105">
                      <button
                        onClick={openChainModal}
                        type="button"
                        className="w-32 flex justify-center p-4 relative border-white rounded-lg bg-gradient-to-b from-blue-500 to-custom-dark-end mt-3 hover:from-blue-600  hover:to-custom-dark-end"
                      >
                        Wrong network
                      </button>
                    </div>
                  )}
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </header>
  );
};

export default Header;
