import React, { useState, useEffect } from "react";
import popupimg from "../assets/images/popup-swap-img.webp";

const InfoPopup = () => {
  const [isOpen, setIsOpen] = useState(false);

  useEffect(() => {
    const timer = setTimeout(() => setIsOpen(true), 500);
    return () => clearTimeout(timer);
  }, []);

  const closePopup = () => setIsOpen(false);

  return (
    <>
      {isOpen && (
        <div
          id="info-popup"
          tabIndex={-1}
          className="fixed inset-0 z-50 flex items-center justify-center bg-gray-900 bg-opacity-50"
        >
          <div className="relative p-4 w-full max-w-xl md:h-auto">
            <div className="relative p-6 boxmain-popup rounded-lg bg-white shadow dark:bg-gray-800 sm:p-8">
              <button
                onClick={closePopup}
                className="absolute top-1 right-0 sm:right-1 text-yellow-500 bg-transparent hover:text-yellow-600"
                aria-label="Close"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  strokeWidth={2}
                  stroke="currentColor"
                  className="w-6 h-6"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M6 18L18 6M6 6l12 12"
                  />
                </svg>
              </button>

              <img
                className="w-full h-auto"
                src={popupimg}
                height={500}
                width={500}
                alt="popup-image"
              />
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default InfoPopup;
